import { emailRegex, LONG_INPUT_LIMIT, urlRegex } from "./validator";

export const setInputType = (type, name) => {
  if (name === "email" && type === "string") {
    return "email";
  } else if (type === "date") {
    return "date";
  } else if (type === "float" || type === "integer") {
    return "number";
  } else if (type === "image") {
    return "file";
  } else if (type === "datetime") {
    return "datetime";
  } else if (type === "url" || type === "image_url" || type === "video_url") {
    return "url";
  } else {
    return "text";
  }
};

export function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1);
}

export const getValidatorRules = (type, isRequired, selectedType, name) => {
  let rules = {};
  switch (type) {
    case "badgeInfo":
      rules = {
        pattern: {
          value: isRequired,
          message: `Badge is required!`,
        },
      };
      break;
    case "email":
      rules = {
        pattern: {
          value: emailRegex,
          message: "Enter valid Email!",
        },
      };
      break;
    case "date":
      break;
    case "number":
      rules = {
        maxLength: {
          value: LONG_INPUT_LIMIT,
          message: `Maximum ${LONG_INPUT_LIMIT} character allowed!`,
        },
      };
      break;
    case "datetime":
      break;
    case "url":
      rules = {
        pattern: {
          value: urlRegex,
          message: "Enter valid URL!",
        },
      };
      break;
    case "text":
      rules = {
        maxLength: {
          value: LONG_INPUT_LIMIT,
          message: `Maximum ${LONG_INPUT_LIMIT} character allowed !`,
        },
      };
      break;
    default:
      break;
  }
  rules = {
    ...rules,
    required: {
      value: isRequired,
      message: `${capitalize(name).replaceAll("_", " ")} is required!`,
    },
  };
  return rules;
};

export const onNumberFieldKeyDown = (event, type, selectedType) => {
  if (event.keyCode === 38 || event.keyCode === 40) {
    event.preventDefault();
  }
  if (
    (event.key === "e" ||
      event.key === "E" ||
      event.keyCode === 107 ||
      event.keyCode === 109 ||
      event.keyCode === 187 ||
      event.keyCode === 189 ||
      ((event.keyCode === 190 || event.keyCode === 110) &&
        selectedType === "integer")) &&
    type === "number"
  ) {
    event.preventDefault();
  }
};

export function trimObjValues(obj) {
  return Object.keys(obj).reduce((acc, curr) => {
    acc[curr] = (obj?.[curr] ?? "")?.trim?.();
    return acc;
  }, {});
}
