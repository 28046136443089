import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "assets/images/svg/plus-white.svg";
import VerifiedIcon from "assets/images/svg/verification-white.svg";
import clsx from "clsx";
import React, { useState } from "react";
// import SearchIcon from "assets/images/svg/search-blue.svg";
import CustomTooltip from "components/CustomTooltip";
import { useHistory } from "react-router-dom";
import VerfiedDrawer from "../Header/components/VerifiedDrawer";
import { useDispatch, useSelector } from "react-redux";
import { setPricingPlanAlert } from "store/commonActions";

const useStyles = makeStyles((theme) => ({
  fullList: {
    width: "auto",
  },
  siteAside: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 4,
    "& ::-webkit-scrollbar": {
      width: 0,
      height: 0,
      background: "transperent",
    },
    [theme.breakpoints.down("xl")]: {
      width: "100px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "106px",
    },
  },
  sidebarLeftSide: {
    borderRight: "none",
    boxShadow: "1.5px 1.5px 3px 0 rgba(0, 0, 0, 0.1)",
    background: "#f6f6f6",
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      padding: "15px 15px 0",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "26px 18px 18px",
    },
  },
  headerButton: {
    width: "44px",
    height: "44px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    backgroundColor: "#083f85",
    borderRadius: "100%",
    margin: "15px 15px 0",
    "&:hover": {
      backgroundColor: "#083f85",
    },
    "&.gray-button": {
      backgroundColor: "#ececec",
    },
  },
  iconWrap: {
    lineHeight: 0,
    display: "block",
    position: "relative",
    "& img": {
      width: "16px",
      height: "auto",
      paddingBottom: " 3px",
      paddingLeft: "0.5px",
    },
  },
  siderbarMenuItem: {
    minWidth: 45,
  },
}));

function HeaderMenu() {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({
    right: false,
  });
  // const [notificationEL, SetNotificationEL] = useState(null);
  // const notificationRef = useRef(null);
  const dispatch = useDispatch();
  const [openVerifiedDrawer, setOpenVerifiedDrawer] = useState(false);
  const userDetails = useSelector((state) => state.getUser.data);
  const { features: { number_of_credentials } = {} } = userDetails;
  const canIssueCredentials = number_of_credentials > 0;
  // const handleNotification = (event) => {
  //   SetNotificationEL(notificationRef.current);
  // };
  const handleIssuanceProcess = () => {
    if (canIssueCredentials) {
      history.push("/issuance-process");
    } else {
      dispatch(setPricingPlanAlert(true));
    }
  };

  // const handleNotificationClose = () => {
  //   SetNotificationEL(null);
  // };
  const toggleVerifiedDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenVerifiedDrawer(open);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <CustomTooltip title="Recently Verified">
        <IconButton
          className={classes.headerButton}
          onClick={toggleVerifiedDrawer(true)}
        >
          <i className={classes.iconWrap}>
            <img src={VerifiedIcon} alt="Verify" />
          </i>
        </IconButton>
      </CustomTooltip>
      <CustomTooltip
        title={"Issuance Process"}
        // title={canIssueCredentials ? "Issuance Process" : "Select Plan"}
      >
        <IconButton
          className={classes.headerButton}
          onClick={handleIssuanceProcess}
          // disabled={!canIssueCredentials}
        >
          <i className={classes.iconWrap}>
            <img src={AddIcon} alt="add" />
          </i>
        </IconButton>
      </CustomTooltip>
      {/* <CustomTooltip title="Search">
        <IconButton
          className={`${classes.headerButton} gray-button`}
          style={{ display: "none" }}
        >
          <i className={classes.iconWrap}>
            <img src={SearchIcon} alt="search" />
          </i>
        </IconButton>
      </CustomTooltip> */}
      {/* <CustomTooltip title="Notifications">
        <IconButton
          className={`${classes.headerButton} gray-button`}
          onClick={handleNotification}
          ref={notificationRef}
        >
          <i className={classes.iconWrap}>
            <img src={BellIcon} alt="search" />
            <Badge variant="dot" className={classes.dot}></Badge>
          </i>
        </IconButton>
      </CustomTooltip> */}
    </div>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            className={classes.siderbarMenuItem}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={toggleDrawer(anchor, true)}
          >
            <MoreVertIcon />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
          <VerfiedDrawer
            open={openVerifiedDrawer}
            toggleDrawer={toggleVerifiedDrawer}
          />
        </React.Fragment>
      ))}
    </div>
  );
}

export default React.memo(HeaderMenu);
