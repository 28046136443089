import authRoles from "core/auth/authRoles";
import { lazy } from "react";
const IssuanceProcess = lazy(() => import("./IssuanceProcess"));

const issuanceProcessConfig = {
  component: IssuanceProcess,
  path: "/issuance-process",
  exact: true,
  layout: {
    config: {
      navbar: {
        display: true,
      },
      sidebar: {
        display: true,
      },
      midbar: {
        display: false,
      },
      footer: {
        display: false,
      },
    },
  },
  auth: authRoles.organization,
  protected: true,
  canShow:
    "userDetails.is_plan_active || userDetails?.features.number_of_credentials > 0",
};

export default issuanceProcessConfig;
