import moment from "moment";

export const getDateString = (dateString) => {
  return dateString.includes("T") || dateString.includes("t")
    ? new Date(dateString + "Z")
    : dateString.replaceAll("-", "");
};

export const getFormattedDateString = (dateString) =>
  dateString?.includes("Z") ? dateString : `${dateString}Z`;

export const getDate = (dateString="") => {
  const newDate = getDateString(dateString);
  return moment(newDate).local().format("DD MMMM YYYY");
};

export const getTime = (dateString) => {
  const newDate = getDateString(dateString);
  return moment(newDate).local().format("LT");
};

export const dateToUTC = (date) => {
  if (!date) return "";
  return moment(date).utc().format();
};