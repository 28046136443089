import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import makeStyles from "@material-ui/styles/makeStyles";
import { ReactComponent as CredChainIcon } from "assets/images/svg/cred-chain-icon.svg";
import { ReactComponent as EthereumIcon } from "assets/images/svg/ethereum.svg";
import { ReactComponent as EverycredWallet } from "assets/images/svg/everycred-wallet.svg";
import { ReactComponent as MetamaskWallet } from "assets/images/svg/metamask-wallet.svg";
import { ReactComponent as OpenBadgeIcon } from "assets/images/svg/openbadge.svg";
import { ReactComponent as PolygonIcon } from "assets/images/svg/polygon.svg";
import { ReactComponent as ProfileIcon } from "assets/images/svg/profile.svg";
import { ReactComponent as SettingsIcon } from "assets/images/svg/settingsmid.svg";
import { ReactComponent as W3CIcon } from "assets/images/svg/w3c.svg";
import CustomTooltip from "components/CustomTooltip";
import MidBarList from "components/Skeletons/MidBarList";
import React, { useEffect, useMemo, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { getDate, getInitials } from "utils";
import useWidth from "utils/useWidth";
import {
  changePage,
  getList,
  getMoreListItem,
  setMidbarParentRef,
} from "../../midbar.action";

const useStyles = makeStyles((theme) => ({
  issuersList: {
    marginBottom: 15,
    width: "91.5%",
    [theme.breakpoints.between("sm", "md")]: {
      width: "92.5%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  navLink: {
    cursor: "pointer",
    textDecoration: "none",
    position: "relative",
    backgroundColor: "#ffffff",
    border: "solid 1px #d5d5d5",
    borderRadius: "10px",
    display: "flex",
    padding: "20px 20px 20px 30px",
    transition: "all 0.5s ease",
    alignItems: "center",
    "& .standard-icon": {
      width: "100%",
      height: "100%",
      backgroundColor: "transparent",
      borderRadius: 5,
      border: "1px solid #083f85",
    },

    "&:hover": {
      backgroundColor: "#083f85",
      "& $issusersLogo": {
        backgroundColor: "#ffffff",
      },
      "& $tabTitle": {
        color: "#fff",
      },
      "& $logoWrapper": {
        backgroundColor: "#fff",
        "& svg": {
          fill: "#083f85",
        },
      },
      "& $issuersDetails": {
        color: "#ffffff",
        "& $certificateTypeWrapper": {
          "& svg": {
            "& .openbadge, & .ethereum, & .polygon, & .w3c, & .metamask, & .everycred, & .credchain":
              {
                fill: "#fff",
              },
            "& .st0, & .st1, & .st2, & .st3": {
              fill: "#fff",
            },
          },
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
    [theme.breakpoints.down("xs")]: {},
  },
  issusersLogo: {
    backgroundColor: "#efefef",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 60,
    flex: "0 0 60px",
    height: 60,
    [theme.breakpoints.down("xl")]: {
      marginRight: "15px",
    },
    [theme.breakpoints.up("xl")]: {
      marginRight: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 10,
      width: 50,
      height: 50,
      flex: "0 0 50px",
      marginRight: 10,
    },
    "& img": {
      maxWidth: 40,
      maxHeight: 40,
      objectFit: "cover",
    },
  },
  issuersDetails: {
    wordBreak: "break-all",
    color: "#083f85",
    "& h4": {
      display: "-webkit-box",
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "2",
      wordBreak: "break-word",
      lineHeight: 1.4,
    },
  },
  listName: {
    "-webkit-line-clamp": 2,
    overflow: "hidden",
    "-webkit-box-orient": "vertical",
    display: "-webkit-box",
  },
  mailIcon: {
    marginRight: 5,
    position: "relative",
    top: "4px",
    width: "14px",
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  activeLink: {
    backgroundColor: "#083f85",
    // transition: "all 2s ease",
    "& div": {
      color: "#ffffff",
    },
    "& $logoWrapper": {
      backgroundColor: "#fff",
      "& svg": {
        fill: "#083f85",
      },
    },
    "& $tabTitle": {
      color: "#fff",
    },
    "& $issusersLogo": {
      backgroundColor: "#ffffff",
    },
    "& $issuersDetails": {
      color: "#ffffff",
      "& $certificateTypeWrapper": {
        "& svg": {
          "& .openbadge, & .ethereum, & .polygon, & .w3c, & .metamask, & .everycred, & .credchain":
            {
              fill: "#fff",
            },
          "& .st0, & .st1, & .st2, & .st3": {
            fill: "#fff",
          },
        },
      },
    },
    "&::after": {
      content: `' '`,
      position: "absolute",
      backgroundColor: "#083f85",
      width: 4,
      height: 50,
      top: "50%",
      right: -32,
      transform: "translate(0, -50%)",
      borderRadius: "20px",
      "@media (max-width: 1680px)": {
        right: -25,
      },
      "@media (max-width: 1440px)": {
        right: -20,
      },
      "@media (max-width: 1025px)": {
        display: "none",
      },
    },
    "&::before": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "0",
      right: "-18px",
      top: "50%",
      transform: "translate(0, -50%)",
      borderTop: "13px solid transparent",
      borderLeft: "20px solid #083f85",
      borderBottom: "13px solid transparent",
      borderRadius: "0 0 85% 0",
      "@media (max-width: 1680px)": {
        right: -20,
      },
      "@media (max-width: 1440px)": {
        right: -15,
      },
      "@media (max-width: 1025px)": {
        display: "none",
      },
    },
  },
  contentWrapper: {
    [theme.breakpoints.down("xl")]: {
      padding: "20px",
    },
  },
  message: {
    textAlign: "center",
    fontSize: "10px",
    textTransform: "capitalize",
  },
  logoWrapper: {
    padding: 7,
    width: 40,
    height: 40,
    backgroundColor: "#083f85",
    borderRadius: "50%",
    border: "solid 0.5px #d5d5d5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fill: "#fff",
    },
  },
  tabTitle: {
    color: "#083f85",
    paddingLeft: 20,
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 10,
    },
  },
  logoWrapperMain: { display: "flex", alignItems: "center" },
  settings: {
    marginBottom: 15,
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
      width: "48%",
      marginRight: 12,
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "100%",
    },
  },
  certificateTypeWrapper: {
    // width: 23,
    // height: 23,
    // marginTop: 5,
  },
  standardsWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "5px",
  },
}));

function SettingsBody() {
  const classes = useStyles();
  let { path } = useRouteMatch();
  const history = useHistory();
  let { pathname } = useLocation();
  const { mainContentRef } = useSelector((state) => state.midBar);

  useEffect(() => {
    if (pathname === "/settings") {
      history.push(`${path}/profile`);
    }
  }, [path, history, pathname]);

  const handleClick = () => {
    mainContentRef?.current.classList.toggle("current");
  };
  return (
    <div>
      <div
        className={[classes.issuersList, classes.settings].join(" ")}
        onClick={handleClick}
        aria-hidden="true"
      >
        <NavLink
          className={classes.navLink}
          to={`${path}/profile`}
          activeClassName={classes.activeLink}
        >
          <div className={classes.logoWrapperMain}>
            <div className={classes.logoWrapper}>
              <ProfileIcon />
            </div>
            <div className={classes.tabTitle}>
              <Typography variant="h6" component="p">
                Profile
              </Typography>
            </div>
          </div>
        </NavLink>
      </div>
      <div
        className={[classes.issuersList, classes.settings].join(" ")}
        onClick={handleClick}
        aria-hidden="true"
      >
        <NavLink
          className={classes.navLink}
          to={`${path}/security`}
          activeClassName={classes.activeLink}
        >
          <div className={classes.logoWrapperMain}>
            <div className={classes.logoWrapper}>
              <SettingsIcon />
            </div>
            <div className={classes.tabTitle}>
              <Typography variant="h6" component="p">
                Security
              </Typography>
            </div>
          </div>
        </NavLink>
      </div>
    </div>
  );
}

const Body = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const width = useWidth();
  const parentRef = useRef(null);

  useEffect(() => {
    dispatch(setMidbarParentRef(parentRef));
  }, [dispatch, parentRef]);

  const { loading, orderBy, data, page, rowPerPage, mainContentRef } =
    useSelector((state) => state.midBar);

  useEffect(() => {
    let url = "/user/issuer";
    let params = {};
    let unmounted = false;
    if (path.match("/credentials")) {
      params = {
        type: "active",
        order_by: orderBy,
      };
    } else if (path.match("/issuers")) {
      params = {
        type: "all",
        order_by: orderBy,
      };
    } else if (path.match("/subjects")) {
      url = "/user/subjects";
      params = {
        type: "all",
        order_by: orderBy,
      };
    } else if (path.match("/badges")) {
      url = "/badges";
      params = {
        type: "all",
        order_by: orderBy,
      };
    } else if (path.match("/holders")) {
      url = "/user/holders";
      params = {
        order_by: orderBy,
      };
    } else if (path.match("/settings")) {
      return;
    } else if (path.match("/activities")) {
      url = "/user/batches";
    }
    Object.assign(params, { page: 1, per_page: rowPerPage });
    if (!unmounted) {
      dispatch(getList(url, params, path, pathname, history, width));
    }
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line
  }, [dispatch, path, orderBy, history]);

  useEffect(() => {
    let url = "/user/issuer";
    let params = {};
    let unmounted = false;

    if (path.match("/credentials")) {
      params = {
        type: "active",
        order_by: orderBy,
      };
    } else if (path.match("/issuers")) {
      params = {
        type: "all",
        order_by: orderBy,
      };
    } else if (path.match("/subjects")) {
      url = "/user/subjects";
      params = {
        type: "all",
        order_by: orderBy,
      };
    } else if (path.match("/badges")) {
      url = "/badges";
      params = {
        type: "all",
        order_by: orderBy,
      };
    } else if (path.match("/holders")) {
      url = "/user/holders";
      params = {
        order_by: orderBy,
      };
    } else if (path.match("/settings")) {
      return;
    } else if (path.match("/activities")) {
      url = "/user/batches";
    }

    Object.assign(params, { page, per_page: rowPerPage });

    if (page > 1 && !unmounted) {
      dispatch(getMoreListItem(url, params));
    }

    return () => {
      unmounted = true;
    };

    // eslint-disable-next-line
  }, [dispatch, page]);

  const listBody = (data) => {
    const standards = {
      w3c: {
        icon: <W3CIcon />,
        title: "W3C-Verifiable Credential",
      },
      openbadge: {
        icon: <OpenBadgeIcon />,
        title: "Openbadge Credential",
      },
    };

    const modes = {
      ethereum_ropsten: {
        icon: <EthereumIcon />,
        title: "Ethereum Testnet",
      },
      ethereum_mainnet: {
        icon: <EthereumIcon />,
        title: "Ethereum Mainnet",
      },
      ethereum_sepolia: {
        icon: <EthereumIcon />,
        title: "Ethereum Sepolia",
      },
      polygon_mainnet: {
        icon: <PolygonIcon />,
        title: "Polygon Mainnet",
      },
      polygon_testnet: {
        icon: <PolygonIcon />,
        title: "Polygon Testnet",
      },
      polygon_amoy: {
        icon: <PolygonIcon />,
        title: "Polygon Amoy Testnet",
      },
      evrc_chain: {
        icon: <CredChainIcon />,
        title: "CREDChain",
      },
    };

    const wallet = {
      evrc: {
        icon: <EverycredWallet />,
        title: "Everycred Wallet",
      },
      issuer: {
        icon: <MetamaskWallet />,
        title: "MetaMask Wallet",
      },
    };

    let temp = (
      <>
        <CustomTooltip title={data.name}>
          <div>
            <Typography
              variant="subtitle2"
              component="h4"
              className={classes.listName}
            >
              {data.name}
            </Typography>
          </div>
        </CustomTooltip>
        <CustomTooltip title={data.email}>
          <Typography
            variant="body2"
            component="p"
            className={classes.listName}
          >
            <MailOutlineIcon fontSize="small" className={classes.mailIcon} />
            {data.email}
          </Typography>
        </CustomTooltip>
        <div className={classes.standardsWrapper}>
          <CustomTooltip title={standards[data?.issuer_type]?.title}>
            <figure className={classes.certificateTypeWrapper}>
              {standards[data?.issuer_type]?.icon}
            </figure>
          </CustomTooltip>
          <CustomTooltip title={modes[data?.mode]?.title}>
            <figure className={classes.certificateTypeWrapper}>
              {modes[data?.mode]?.icon}
            </figure>
          </CustomTooltip>
          <CustomTooltip title={wallet[data?.wallet_holder_type]?.title}>
            <figure className={classes.certificateTypeWrapper}>
              {wallet[data?.wallet_holder_type]?.icon}
            </figure>
          </CustomTooltip>
        </div>
      </>
    );
    if (path.match("/subjects")) {
      temp = (
        <>
          <CustomTooltip title={data.title}>
            <Typography variant="subtitle2" component="h4">
              {data.title}
            </Typography>
          </CustomTooltip>
          <Typography variant="body2">
            {data["Credentials Issued"]
              ? `${data["Credentials Issued"]} Credentials Issued`
              : "Credentials Not Issued"}
          </Typography>
        </>
      );
    }
    if (path.match("/badge")) {
      temp = (
        <>
          <CustomTooltip title={data.title}>
            <Typography variant="subtitle2" component="h4">
              {data.title}
            </Typography>
          </CustomTooltip>
          <Typography variant="body2">
            {data["Credentials Issued"]
              ? `${data["Credentials Issued"]} Credentials Issued`
              : "Credentials Not Issued"}
          </Typography>
        </>
      );
    }
    if (path.match("/activities")) {
      temp = (
        <>
          <CustomTooltip title={data.name}>
            <Typography variant="subtitle2" component="h4">
              {data.name}
            </Typography>
          </CustomTooltip>
          <Typography variant="body2">{getDate(data.created_at)}</Typography>
          <div className={classes.standardsWrapper}>
            <CustomTooltip title={standards[data?.issuer_type]?.title}>
              <figure className={classes.certificateTypeWrapper}>
                {standards[data?.issuer_type]?.icon}
              </figure>
            </CustomTooltip>
            <CustomTooltip title={modes[data?.mode]?.title}>
              <figure className={classes.certificateTypeWrapper}>
                {modes[data?.mode]?.icon}
              </figure>
            </CustomTooltip>
            <CustomTooltip title={wallet[data?.wallet_type]?.title}>
              <figure className={classes.certificateTypeWrapper}>
                {wallet[data?.wallet_type]?.icon}
              </figure>
            </CustomTooltip>
          </div>
        </>
      );
    }
    if (path.match("/holders")) {
      temp = (
        <>
          <CustomTooltip title={data.name}>
            <div>
              <Typography
                variant="subtitle2"
                component="h4"
                className={classes.listName}
              >
                {data.name}
              </Typography>
            </div>
          </CustomTooltip>
          <CustomTooltip title={data.email}>
            <Typography
              variant="body2"
              component="p"
              className={classes.listName}
            >
              <MailOutlineIcon fontSize="small" className={classes.mailIcon} />
              {data.email}
            </Typography>
          </CustomTooltip>
        </>
      );
    }

    return temp;
  };

  const handleMidbarAction = () => {
    mainContentRef?.current.classList.toggle("current");
  };

  let list = data?.list?.map((data) => {
    let { id, logo, subject_image, profile_image, name } = data;
    let redirectPath = "";
    if (path.match("/credentials")) {
      redirectPath = `${path}/credential-details/${id}`;
    } else if (path.match("/issuers")) {
      redirectPath = `${path}/issuer-details/${id}`;
    } else if (path.match("/subjects")) {
      redirectPath = `${path}/subject-details/${id}`;
    } else if (path.match("/badges")) {
      redirectPath = `${path}/badge-details/${id}`;
    } else if (path.match("/holders")) {
      redirectPath = `${path}/holder-details/${id}`;
    } else if (path.match("/activities")) {
      redirectPath = `${path}/activities-details/${id}`;
    }
    return (
      <div
        className={classes.issuersList}
        key={id}
        onClick={handleMidbarAction}
        aria-hidden="true"
      >
        <NavLink
          className={classes.navLink}
          to={redirectPath}
          activeClassName={classes.activeLink}
        >
          <div className={classes.issusersLogo}>
            {logo || subject_image || profile_image ? (
              <Avatar
                variant="rounded"
                alt="User profile"
                src={logo || subject_image || profile_image}
              />
            ) : (
              <Avatar variant="rounded">{getInitials(name)}</Avatar>
            )}
          </div>
          <div className={classes.issuersDetails}>{listBody(data)}</div>
        </NavLink>
      </div>
    );
  });

  let message =
    !data?.list?.length && !loading ? (
      <div className={classes.message}>
        <Typography variant="body1" color="primary">
          No {path.replace("/", "").replaceAll("-", " ")}
        </Typography>
      </div>
    ) : null;

  let dataList = loading && !data?.list?.length ? <MidBarList /> : list;

  let midbarBody;
  let settingBody = null;

  if (path.includes("/settings")) {
    settingBody = <SettingsBody />;
  } else {
    midbarBody = dataList;
  }

  const fetchMoreData = (event) => {
    dispatch(changePage(page + 1));
  };

  const hasMoreData = useMemo(
    () => page < data?.pages && !path.match("/settings"),
    [data?.pages, page, path]
  );

  return (
    <>
      {!settingBody ? (
        <div
          style={
            data?.list?.length
              ? { height: "100%", overflow: "auto" }
              : { overflow: "auto" }
          }
          ref={parentRef}
        >
          {parentRef?.current ? (
            <InfiniteScroll
              dataLength={data?.list?.length || 0}
              next={fetchMoreData}
              hasMore={hasMoreData}
              loader={<h4>Loading...</h4>}
              scrollableTarget={parentRef?.current}
              scrollThreshold={0.7}
              className="navlinkWrapper"
            >
              {midbarBody}
            </InfiniteScroll>
          ) : null}
        </div>
      ) : null}
      {settingBody || null}
      {!path.match("/settings") ? message : null}
    </>
  );
};

export default React.memo(Body);
