import {
  GET_PLANS_BEGIN,
  GET_PLANS_FAILURE,
  GET_PLANS_SUCCESS,
  SELECT_PLAN_BEGIN,
  SELECT_PLAN_FAILURE,
  SELECT_PLAN_SUCCESS,
  GET_CURRENT_ACTIVE_PLAN_BEGIN,
  GET_CURRENT_ACTIVE_PLAN_SUCCESS,
  GET_CURRENT_ACTIVE_PLAN_FAILURE,
  GET_PURCHASE_HISTORY_BEGIN,
  GET_PURCHASE_HISTORY_SUCCESS,
  GET_PURCHASE_HISTORY_FAILURE,
} from "./Pricing.constant";

const initState = {
  plansList: {
    loading: false,
    data: {},
    error: "",
  },
  selectPlan: {
    loading: false,
    data: [],
    error: "",
    planName: "",
    planType: "",
  },
  purchaseHistory: {
    loading: false,
    data: {},
    error: "",
  },
  currentActivePlan: { loading: false, data: {}, error: "" },
};

export const pricingReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_PLANS_BEGIN:
      return {
        ...state,
        plansList: {
          ...state.plansList,
          loading: true,
        },
      };
    case GET_PLANS_SUCCESS:
      return {
        ...state,
        plansList: {
          ...state.plansList,
          loading: false,
          data: action.payload,
        },
      };
    case GET_PLANS_FAILURE:
      return {
        ...state,
        plansList: {
          ...state.plansList,
          loading: false,
          error: action.payload,
        },
      };

    case SELECT_PLAN_BEGIN:
      const { planName, planType } = action.payload;
      return {
        ...state,
        selectPlan: {
          ...state.selectPlan,
          loading: true,
          planName,
          planType,
        },
      };
    case SELECT_PLAN_SUCCESS:
      return {
        ...state,
        selectPlan: {
          ...state.selectPlan,
          loading: false,
          data: action.payload,
          planName: "",
          planType: "",
        },
      };
    case SELECT_PLAN_FAILURE:
      return {
        ...state,
        selectPlan: {
          ...state.selectPlan,
          loading: false,
          error: action.payload,
          planName: "",
          planType: "",
        },
      };
    case GET_CURRENT_ACTIVE_PLAN_BEGIN:
      return {
        ...state,
        currentActivePlan: {
          ...state.currentActivePlan,
          loading: true,
        },
      };
    case GET_CURRENT_ACTIVE_PLAN_SUCCESS:
      return {
        ...state,
        currentActivePlan: {
          ...state.currentActivePlan,
          loading: false,
          data: action.payload,
        },
      };
    case GET_CURRENT_ACTIVE_PLAN_FAILURE:
      return {
        ...state,
        currentActivePlan: {
          ...state.currentActivePlan,
          loading: false,
          error: action.payload,
        },
      };

    case GET_PURCHASE_HISTORY_BEGIN:
      return {
        ...state,
        purchaseHistory: {
          ...state.purchaseHistory,
          loading: true,
        },
      };
    case GET_PURCHASE_HISTORY_SUCCESS: {
      const updatedList = [
        ...(state?.purchaseHistory?.data?.data || []),
        ...(action?.payload?.data || []),
      ].reduce(
        (acc, curr) => {
          if (acc[1].indexOf(curr._id) === -1) {
            acc[1].push(curr._id);
            acc[0].push(curr);
          }
          return acc;
        },
        [[], []]
      )[0];
      return {
        ...state,
        purchaseHistory: {
          ...state.purchaseHistory,
          loading: false,
          data: {
            ...(action?.payload || {}),
            data: updatedList,
          },
        },
      };
    }

    case GET_PURCHASE_HISTORY_FAILURE:
      return {
        ...state,
        purchaseHistory: {
          ...state.purchaseHistory,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
