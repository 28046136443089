export const GET_PLANS_BEGIN = "GET_PLANS_BEGIN";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLANS_FAILURE = "GET_PLANS_FAILURE";

export const SELECT_PLAN_BEGIN = "SELECT_PLAN_BEGIN";
export const SELECT_PLAN_SUCCESS = "SELECT_PLAN_SUCCESS";
export const SELECT_PLAN_FAILURE = "SELECT_PLAN_FAILURE ";

export const GET_CURRENT_ACTIVE_PLAN_BEGIN = "GET_CURRENT_ACTIVE_PLAN_BEGIN";
export const GET_CURRENT_ACTIVE_PLAN_SUCCESS =
  "GET_CURRENT_ACTIVE_PLAN_SUCCESS";
export const GET_CURRENT_ACTIVE_PLAN_FAILURE =
  "GET_CURRENT_ACTIVE_PLAN_FAILURE";

export const GET_PURCHASE_HISTORY_BEGIN = " GET_PURCHASE_HISTORY_BEGIN";
export const GET_PURCHASE_HISTORY_SUCCESS = " GET_PURCHASE_HISTORY_SUCCESS";
export const GET_PURCHASE_HISTORY_FAILURE = " GET_PURCHASE_HISTORY_FAILURE";
