import {
  GET_BADGE_INFO_BEGIN,
  GET_BADGE_INFO_SUCCESS,
  GET_BADGE_INFO_FAILURE,
  SET_BADGE_INFO,
  GET_CREDENTIAL_LIST_FOR_BADGE_BEGIN,
  GET_CREDENTIAL_LIST_FOR_BADGE_SUCCESS,
  GET_CREDENTIAL_LIST_FOR_BADGE_FAILURE,
  ADD_RECORD_FOR_BADGE_BEGIN,
  ADD_RECORD_FOR_BADGE_SUCCESS,
  ADD_RECORD_FOR_BADGE_FAILURE,
  UPLOAD_RECORD_FOR_BADGE_BEGIN,
  UPLOAD_RECORD_FOR_BADGE_SUCCESS,
  UPLOAD_RECORD_FOR_BADGE_FAILURE,
  GET_ALL_RECORD_FOR_BADGE_BEGIN,
  GET_ALL_RECORD_FOR_BADGE_SUCCESS,
  GET_ALL_RECORD_FOR_BADGE_FAILURE,
  GET_ISSUER_LIST_BEGIN,
  GET_ISSUER_LIST_FAILURE,
  GET_ISSUER_LIST_SUCCESS,
} from "./badgeDetails.constants";

const initialstate = {
  loading: false,
  error: "",
  response: {},
};

export function getBadgeInfoReducer(state = initialstate, action) {
  switch (action.type) {
    case GET_BADGE_INFO_BEGIN:
      return { ...state, loading: true };
    case GET_BADGE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case GET_BADGE_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_BADGE_INFO:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    default:
      return state;
  }
}

// Get list of Credential for Specific Badge

const initState = {
  loading: false,
  error: "",
  response: {},
};

export function getCredentialListForBadgeReducer(state = initState, action) {
  switch (action.type) {
    case GET_CREDENTIAL_LIST_FOR_BADGE_BEGIN:
      return { ...state, loading: true, error: "", response: {} };
    case GET_CREDENTIAL_LIST_FOR_BADGE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        error: "",
      };
    case GET_CREDENTIAL_LIST_FOR_BADGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        response: {},
      };
    default:
      return state;
  }
}

// Add Record for Specific Badge

export function addRecordForBadgeReducer(state = initialstate, action) {
  switch (action.type) {
    case ADD_RECORD_FOR_BADGE_BEGIN:
      return { ...state, loading: true, error: "" };
    case ADD_RECORD_FOR_BADGE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {},
        error: "",
      };
    case ADD_RECORD_FOR_BADGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Upload Record (CSV file) for specific badge
export function uploadRecordForBadgeReducer(state = initialstate, action) {
  switch (action.type) {
    case UPLOAD_RECORD_FOR_BADGE_BEGIN:
      return { ...state, loading: true, error: "" };
    case UPLOAD_RECORD_FOR_BADGE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {},
        error: "",
      };
    case UPLOAD_RECORD_FOR_BADGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export function getAllRecordsForBadgeReducer(state = initialstate, action) {
  switch (action.type) {
    case GET_ALL_RECORD_FOR_BADGE_BEGIN:
      return { ...state, loading: true };
    case GET_ALL_RECORD_FOR_BADGE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case GET_ALL_RECORD_FOR_BADGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export function getIssuerListReducer(state = initialstate, action) {
  switch (action.type) {
    case GET_ISSUER_LIST_BEGIN:
      return { ...state, loading: true };
    case GET_ISSUER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case GET_ISSUER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
