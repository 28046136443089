import authRoles from "core/auth/authRoles";
import { lazy } from "react";
const Credentials = lazy(() => import("./Credentials"));

const credentialsConfig = {
  component: Credentials,
  path: "/credentials",
  exact: false,
  layout: {
    config: {
      navbar: {
        display: true,
      },
      sidebar: {
        display: true,
      },
      midbar: {
        display: true,
      },
      footer: {
        display: true,
      },
    },
  },
  auth: authRoles.organization,
  protected: true,
  // canShow: "userDetails.is_plan_active || userDetails.is_issuer",
};

export default credentialsConfig;
