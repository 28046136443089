import authRoles from "core/auth/authRoles";
import { lazy } from "react";
const Subject = lazy(() => import("./Subjects"));

const subjectConfig = {
  component: Subject,
  path: "/subjects",
  exact: false,
  layout: {
    config: {
      navbar: {
        display: true,
      },
      sidebar: {
        display: true,
      },
      midbar: {
        display: true,
      },
      footer: {
        display: true,
      },
    },
  },
  auth: authRoles.organization,
  protected: true,
  // canShow: "userDetails.is_plan_active || userDetails.is_subject",
};

export default subjectConfig;
