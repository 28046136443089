import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "assets/images/svg/everycred-logo.svg";
import { ReactComponent as HolderDidIcon } from "assets/images/svg/holder-did.svg";
import { ReactComponent as BadgeIcon } from "assets/images/svg/badge.svg";
import SidebarNavItem from "components/SidebarNavItem";
import React, { useCallback, useMemo } from "react";
import { BiLogOut, BiTachometer } from "react-icons/bi";
import { BsCardList } from "react-icons/bs";
import { CgUserList } from "react-icons/cg";
import { FiSettings } from "react-icons/fi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { IoIosPricetag } from "react-icons/io";
import { MdInfoOutline } from "react-icons/md";
import { TiKeyOutline } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toggleLogoutModal, toggleSideBar } from "store/commonActions";
import { objectsAreSame } from "utils";
import useWidth from "utils/useWidth";
import { changeOrderBy, changePage } from "../Midbar/midbar.action";
import MobileSidebar from "../MobileSidebar/MobileSidebar";

const useStyles = makeStyles((theme) => ({
  siteAside: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 4,
    "& ::-webkit-scrollbar": {
      width: 0,
      height: 0,
      background: "transperent",
    },
    [theme.breakpoints.down("xl")]: {
      width: "100px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "106px",
    },
  },
  sidebarLeftSide: {
    borderRight: "none",
    boxShadow: "1.5px 1.5px 3px 0 rgba(0, 0, 0, 0.1)",
    background: "#f6f6f6",
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      padding: "15px 15px 0",
      scrollbarWidth: "none",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "26px 18px 18px",
    },
  },
  sidebarLogo: {
    padding: "0",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    [theme.breakpoints.down("xl")]: {
      margin: "0 auto 70px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "20px auto 30px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "10px 0 20px",
      width: "100%",
    },
    "& img": {
      width: "100%",
      height: 47,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "60%",
      },
    },
  },
  sideBarNav: {
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
    },
  },
  sidebarNavLi: {
    padding: 0,
    [theme.breakpoints.down("xl")]: {
      margin: "0 0 20px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 0px",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "0 0 18px",
    },
  },
  sidebarNavItemIcon: {
    lineHeight: 0,
    "& svg": {
      height: "auto",
      width: "20px",
      color: "#083f85",
    },
  },
  sidebarNavItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: "1px solid #d5d5d5",
    background: "#ffffff",
    borderRadius: "20px",
    color: "#747474",
    width: "100%",
    textDecoration: "none",
    cursor: "pointer",
    [theme.breakpoints.down("xl")]: {
      padding: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 10,
      margin: "5px 0",
    },
    "&:hover": {
      color: "#ffffff",
      background: "#083f85",
      "& .nav-icon-wrap": {
        background: "#ffffff",
        "& i": {
          color: "#083f85",
        },
      },
    },
  },
  activeLink: {
    color: "#ffffff",
    background: "#083f85",
    transition: "all 2s ease",
    "& .nav-icon-wrap": {
      background: "#ffffff",
      "& i": {
        color: "#083f85",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
    "&::after": {
      [theme.breakpoints.down("xl")]: {
        right: "-15px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      [theme.breakpoints.up("xl")]: {
        right: "-18px",
      },
      content: `' '`,
      position: "absolute",
      backgroundColor: "#083f85",
      width: 3,
      height: 25,
      top: "50%",
      transform: "translate(0, -50%)",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      width: "0",
      height: "0",
      left: "97%",
      top: "50%",
      transform: "translate(0, -50%)",
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      [theme.breakpoints.down("xl")]: {
        borderLeft: "8px solid #083f85",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      [theme.breakpoints.up("xl")]: {
        borderLeft: "12px solid #083f85",
      },
    },
  },
  sidebarNavItemText: {
    fontSize: "10px",
    fontFamily: "Roboto",
    fontWeight: "400",
    display: "Block",
  },
  iconWrap: {
    height: "32px",
    width: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    background: "#f6f6f6",
    [theme.breakpoints.down("xl")]: {
      margin: "0 0 6px",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "0 0 8px",
    },
  },
}));

function Sidebar({ display }) {
  const classes = useStyles();
  const width = useWidth();
  const dispatch = useDispatch();
  const history = useHistory();
  const { open: openDrawer } = useSelector(({ sideBar }) => sideBar);
  // const userDetails = useSelector((state) => state.getUser.data);

  const handleClose = useCallback(() => {
    dispatch(toggleSideBar(false));
  }, [dispatch]);

  const setOpenDrawer = useCallback(() => {
    dispatch(toggleSideBar(!openDrawer));
  }, [dispatch, openDrawer]);

  const handleLogout = (event) => {
    dispatch(toggleLogoutModal());
  };

  const handlePageChange = () => {
    dispatch(changePage(1));
    dispatch(changeOrderBy("latest"));
    handleClose();
  };

  const handleCTA = () => {
    history.push("/dashboard");
  };

  const isMobileDevice = useMemo(() => ["xs"].includes(width), [width]);

  const isPermanentDrawer = useMemo(
    () => !openDrawer && !["xs", "sm"].includes(width),
    [width, openDrawer]
  );

  if (!display) return null;

  // const { is_issuer, is_subject, is_plan_active } = userDetails;

  // const canShowIssuer = is_plan_active || is_issuer;
  // const canSubject = is_plan_active || is_subject;

  return (
    <>
      {openDrawer && isMobileDevice ? (
        <MobileSidebar
          display={display}
          handleClose={handleClose}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      ) : (
        <Drawer
          variant={isPermanentDrawer ? "permanent" : "temporary"}
          className={classes.siteAside}
          open={openDrawer}
          onClose={handleClose}
          classes={{ paperAnchorDockedLeft: classes.sidebarLeftSide }}
        >
          <i
            className={classes.sidebarLogo}
            onClick={handleCTA}
            aria-hidden="true"
          >
            <img src={Logo} alt="Everycred Logo" width="41" height="47" />
          </i>
          <List className={classes.sideBarNav}>
            <ListItem className={classes.sidebarNavLi} onClick={handleClose}>
              <SidebarNavItem
                path="/dashboard"
                exact={true}
                itemName="Dashboard"
                icon={<BiTachometer />}
              />
            </ListItem>
            {/* {canShowIssuer && (
              <> */}
            <ListItem
              className={classes.sidebarNavLi}
              onClick={handlePageChange}
            >
              <SidebarNavItem
                path="/credentials"
                exact={false}
                itemName="Credentials"
                icon={<TiKeyOutline />}
              />
            </ListItem>
            <ListItem
              className={classes.sidebarNavLi}
              onClick={handlePageChange}
            >
              <SidebarNavItem
                path="/issuers"
                exact={false}
                itemName="Issuers"
                icon={<HiOutlineUserGroup />}
              />
            </ListItem>
            {/* </>
            )} */}
            {/* {canSubject && (
              <> */}
            <ListItem
              className={classes.sidebarNavLi}
              onClick={handlePageChange}
            >
              <SidebarNavItem
                path="/subjects"
                exact={false}
                itemName="Subjects"
                icon={<BsCardList />}
              />
            </ListItem>
            {/* </>
            )} */}
            <ListItem
              className={classes.sidebarNavLi}
              onClick={handlePageChange}
            >
              <SidebarNavItem
                path="/badges"
                exact={false}
                itemName="Badges"
                icon={<BadgeIcon />}
              />
            </ListItem>

            <ListItem
              className={classes.sidebarNavLi}
              onClick={handlePageChange}
            >
              <SidebarNavItem
                path="/holders"
                exact={false}
                itemName="Holders"
                icon={<HolderDidIcon />}
              />
            </ListItem>

            <ListItem
              className={classes.sidebarNavLi}
              onClick={handlePageChange}
            >
              <SidebarNavItem
                path="/activities"
                exact={false}
                itemName="Logs"
                icon={<CgUserList />}
              />
            </ListItem>

            <ListItem className={classes.sidebarNavLi} onClick={handleClose}>
              <SidebarNavItem
                path="/pricing"
                exact={false}
                itemName="Pricing"
                icon={<IoIosPricetag />}
              />
            </ListItem>

            {/* <ListItem className={classes.sidebarNavLi} onClick={handleClose}>
              <SidebarNavItem
                path="/subscription"
                exact={false}
                itemName="Subscription"
                icon={<MdOutlineSubscriptions />}
              />
            </ListItem> */}

            <ListItem className={classes.sidebarNavLi} onClick={handleClose}>
              <SidebarNavItem
                path="/settings"
                exact={false}
                itemName="Settings"
                icon={<FiSettings />}
              />
            </ListItem>

            <ListItem className={classes.sidebarNavLi} onClick={handleClose}>
              <SidebarNavItem
                path="/about"
                exact={true}
                itemName="About"
                icon={<MdInfoOutline />}
              />
            </ListItem>
            <ListItem className={classes.sidebarNavLi} onClick={handleLogout}>
              <div className={classes.sidebarNavItem}>
                <div className={`${classes.iconWrap} nav-icon-wrap`}>
                  <i className={`${classes.sidebarNavItemIcon}`}>
                    <BiLogOut />
                  </i>
                </div>
                <span className={classes.sidebarNavItemText}>Logout</span>
              </div>
            </ListItem>
          </List>
        </Drawer>
      )}
    </>
  );
}
function areEqual(prevProps, nextProps) {
  return objectsAreSame(prevProps, nextProps);
}

export default React.memo(Sidebar, areEqual);
